import React from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const SocialIcons = () => {
  return (
    <div>
      <a
        href="https://github.com/mgc3"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaGithub size="18px" />
      </a>
      <a
        href="mailto:michael.chun03@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaRegEnvelope size="18px" className="social-icons-space" />
      </a>
      <a
        href="https://www.linkedin.com/in/michaelchun03"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedinIn size="18px" />
      </a>
    </div>
  );
};

export default SocialIcons;
